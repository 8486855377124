<template>
  <div class="nfe-card-wallet">
    <div class="name-file text-truncate">
      <v-icon
        size="20"
        class="mr-2"
      >
        mdi-file-outline
      </v-icon>
      {{ $t('components.wallet.nfe.name_file', { name: identification }) }}
    </div>

    <div class="date-file">
      {{ createdAt }}
    </div>

    <div :class="['status', classStatus]">
      {{ statusText }}
    </div>

    <div
      class="errors-file"
      @click="toggle"
    >
      {{ textMoreInfos }}
      <img
        :class="[arrowIcon, 'arrow']"
        src="@/assets/icons/arrow_green.svg"
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'NfeCardWallet',
  props: {
    identification: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    nextPage: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      showJustification: false,
    };
  },
  computed: {
    textMoreInfos() {
      return this.$t(`components.wallet.nfe.more_infos.${this.showJustification}`);
    },
    arrowIcon() {
      return this.showJustification ? 'show' : '';
    },
    classStatus() {
      const status = this.status.split('_')[0];

      return `${status}-style`;
    },
    statusText() {
      return this.$t(`components.wallet.nfe.status.${this.status}`);
    },
    hasDisapproved() {
      return this.status === 'disapproved';
    },
  },
  watch: {
    nextPage() {
      if (this.active) this.toggle();
    },
  },
  methods: {
    toggle() {
      this.$emit('showErrors');
      this.showJustification = !this.showJustification;
    },
  },
};
</script>
<style lang="scss" scoped>
.nfe-card-wallet {
  min-width: 900px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: $files_backgrond;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  font-weight: 400;
  color: $color_text_card_kind;
  font-size: 1em;
  padding: 0px 20px;

  .name-file {
    max-width: 300px;
    padding-left: 32px;
  }

  .status {
    width: 172px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1em;
    border: none;
    padding: 8px 28px;
    border-radius: 6px;
    color: $color_approved;
    background: $color_background_approved;

    &.pending-style {
      color: $color_pending;
      background: $color_background_pending;
    }

    &.active-style {
      color: $color_approved;
      background: $color_background_approved;
    }

    &.disapproved-style {
      color: $color_disapproved;
      background: $color_background_disapproved;
    }

    &.able-style {
      color: $color_approved;
      background: $color_background_approved;
    }
  }

  .errors-file {
    color: $color_approved;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .arrow {
      margin-left: 4px;
      transition: 500ms;

      &.show {
        transform: rotate(180deg);
      }

    }

  }
}
</style>
