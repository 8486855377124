<template>
  <div class="container-select">
    <div
      class="select"
      @click="toggle"
    >
      <span>
        {{ placeholderText }}
      </span>
      <v-icon
        v-if="expanded"
        size="32"
        class="mr-2 arrow"
      >
        mdi-menu-up
      </v-icon>
      <v-icon
        v-else
        size="32"
        class="mr-2 arrow"
      >
        mdi-menu-down
      </v-icon>
    </div>
    <v-divider
      v-if="expanded"
      color="primary"
    />
    <v-expand-transition>
      <div
        v-if="expanded"
        class="expanded"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <span
            :class="[classSelectedItem(index), 'item']"
            @click="select(item, index)"
          >
            <div class="check">
              <v-icon
                size="18"
              >
                mdi-check
              </v-icon>
            </div>
            {{ item.text }}
          </span>
          <v-divider />
        </div>
        <slot name="custom" />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    toggleExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      selectedItemText: '',
      selectedItemIndex: null,
    };
  },
  computed: {
    placeholderText() {
      if (this.selectedItemText) {
        return this.selectedItemText;
      }
      return this.placeholder;
    },
  },
  watch: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    select(item, index) {
      this.selectedItemText = item.text;
      this.selectedItemIndex = index;
      this.expanded = false;
      this.$emit('change', item.value);
    },
    classSelectedItem(index) {
      if (this.selectedItemIndex === index) {
        return 'selected';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.container-select {
  background-color: $color_white;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 6px 12px 6px 12px;
  position: relative;
  color: $color_gray;

  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .arrow {
      color: $color_primary;
    }
  }

  .expanded {
    z-index: 1;
    position: absolute;
    background-color: $color_white;
    width: 100%;
    left: 0;
    box-shadow: $box_shadow_custom_select;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;

    .item {
      display: flex;
      position: relative;
      margin: 10px 0px 10px 0px;
      font-size: 14px;
      cursor: pointer;

      .check {
        width: 28px;
        margin-left: 8px;

        .mdi-check {
          color: $color_primary;
          display: none;
        }
      }

      &.selected {
        color: $color_dark;

        .check {
          .mdi-check {
            display: block;
          }
        }
      }
    }
  }
}
</style>
