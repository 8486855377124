<template>
  <div class="header-wallet">
    <div> {{ $t('components.wallet.header.nfe') }} </div>
    <div> {{ $t('components.wallet.header.created_at') }} </div>
    <div> {{ $t('components.wallet.header.status') }} </div>
    <div> {{ $t('components.wallet.header.more_infos') }} </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderWallet',
};
</script>

<style lang="scss" scoped>
.header-wallet {
  min-width: 900px;
  height: 54px;
  border-radius: 6px;
  font-size: 1em;
  font-weight: bold;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  background: $app_background;
}
</style>
