<template>
  <v-expand-transition>
    <div
      v-show="active"
      class="card-info-nfe"
    >
      <v-divider />

      <div class="nfe-infos-content">
        <div class="nfe-info">
          <div class="mb-4">
            <span class="text-info">
              {{ $t('components.wallet.card_info_nfe.title') }}
            </span>
          </div>

          <div class="mb-2">
            <span> {{ $t('components.wallet.card_info_nfe.identification') }} </span>
            <span class="text-info">
              {{ nfe.identification }}
            </span>
          </div>

          <div class="mb-2">
            <span> {{ $t('components.wallet.card_info_nfe.issue_date') }} </span>
            <span class="text-info">
              {{ nfe.issue_date }}
            </span>
          </div>

          <div class="mb-2">
            <span> {{ $t('components.wallet.card_info_nfe.status') }} </span>
            <span class="text-info">
              {{ statusText }}
            </span>
          </div>

          <div
            v-if="nfe.message_errors"
            class="mb-2"
          >
            <span> {{ $t('components.wallet.card_info_nfe.errors') }} </span>
            <span class="text-info">
              {{ nfe.message_errors }}
            </span>
          </div>
        </div>

        <div class="infos-content">
          <div class="mb-4">
            {{ $t('components.wallet.card_info_nfe.title_info_item') }}
          </div>

          <div
            v-for="(item, index) in nfe.nfe_items"
            :key="index"
            class="nfe-item"
          >
            <component :is="getIconByMaterialType(mountMaterial(item.material_type_name), false)" />

            <div class="item-info">
              {{ $t('components.wallet.card_info_nfe.item_quantity', {
                value: item.quantity.toLocaleString('pt-BR')
              }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-expand-transition>
</template>
<script>
import getIconsByMaterialType from '@/mixins/getIconsOfMaterialType';

export default {
  name: 'CardInfoNfe',
  mixins: [getIconsByMaterialType],
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },

    nfe: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    statusText() {
      return this.$t(`components.wallet.nfe.status.${this.nfe.status}`);
    },
  },

  methods: {
    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },
  },
};
</script>
<style lang="scss" scoped>
.card-info-nfe {
  min-width: 900px;
  background: $files_backgrond;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 1em;
  margin-bottom: 8px;

  .nfe-infos-content {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    color: $color_secondary;

    .nfe-info {
      font-weight: 400;

      .text-info {
        font-weight: bold;
      }
    }

    .infos-content {
      display: flex;
      align-items: flex-start;
      font-weight: 600;
      flex-direction: column;

      .nfe-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .item-info {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
