<template>
  <div class="wallet">
    <div class="container-wallet">
      <div class="title-wallet">
        {{ $t('components.wallet.title') }}
      </div>
      <div class="filters">
        <div class="content-filters">
          <custom-select
            v-model="period"
            :items="itemsSelectByPeriod"
            :placeholder="this.$t('components.wallet.filters.items_select_by_period.placeholder')"
            :toggle-expanded="toggleExpandedSelectPeriod"
            class="select-by-period mr-4"
            @change="fetchByFilters(true)"
          >
            <template slot="custom">
              <span
                v-if="!showDateRange"
                class="item"
                @click="showInputs"
              >
                {{ $t('components.wallet.filters.items_select_by_period.custom_period.label') }}
              </span>
              <div
                v-if="showDateRange"
                class="custom-inputs"
              >
                <v-text-field
                  v-model="dateInit"
                  v-mask="'##/##/####'"
                  type="text"
                  class="input-period"
                  flat
                  solo
                  background-color="branch_field"
                  :placeholder="translateCustomPeriodInputs('start_period.placeholder')"
                  @keyup="fetchByDateRange"
                />
                <span>-</span>
                <v-text-field
                  v-model="dateEnd"
                  v-mask="'##/##/####'"
                  type="text"
                  class="input-period"
                  flat
                  solo
                  background-color="branch_field"
                  :placeholder="translateCustomPeriodInputs('end_period.placeholder')"
                  @keyup="fetchByDateRange"
                />
              </div>
            </template>
          </custom-select>
          <custom-select
            v-model="status"
            :items="itemsSelectByStatus"
            :placeholder="this.$t('components.wallet.filters.items_select_by_status.placeholder')"
            class="select-by-period"
            @change="fetchByFilters(false)"
          />
        </div>
      </div>
      <div class="content-wallet">
        <div v-if="hasNfes">
          <header-wallet />
          <div class="content-nfes">
            <v-item-group multiple>
              <div
                v-for="(nfe, index) in getMyNfesFiles.data"
                :key="index"
                class="my-6"
              >
                <v-item
                  v-slot="{ active, toggle }"
                >
                  <div>
                    <nfe-card-wallet
                      :identification="nfe.identification"
                      :created-at="nfe.created_at"
                      :status="nfe.status"
                      :active="active"
                      :next-page="pageStatus"
                      @showErrors="toggle"
                    />
                    <card-info-nfe
                      :active="active"
                      :nfe="nfe"
                    />
                  </div>
                </v-item>
              </div>
            </v-item-group>
          </div>
          <rectoplus-pagination
            :size="getMyNfesFiles.pagination_settings.pages"
            :page="getMyNfesFiles.pagination_settings.page"
            @changed="changePage"
          />
        </div>
        <div
          v-else
          class="placeholder-wallet"
        >
          {{ $t('components.wallet.placeholder') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import HeaderWallet from '@/components/headers/wallet/HeaderWallet.vue';
import NfeCardWallet from '@/components/cards/wallet/NfeCardWallet.vue';
import CardInfoNfe from '@/components/cards/wallet/CardInfoNfe.vue';
import RectoplusPagination from '@/components/pagination/RectoplusPagination.vue';
import CustomSelect from '@/components/selects/CustomSelect.vue';

export default {
  name: 'Wallet',

  components: {
    HeaderWallet,
    NfeCardWallet,
    CardInfoNfe,
    RectoplusPagination,
    CustomSelect,
  },

  data() {
    return {
      pageStatus: 1,
      itemsSelectByPeriod: [
        {
          text: this.$t('components.wallet.filters.items_select_by_period.last_day'),
          value: '1',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_period.last_fifteen_days'),
          value: '15',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_period.last_thirty_days'),
          value: '30',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_period.last_six_months'),
          value: '180',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_period.last_year'),
          value: '365',
        },
      ],
      itemsSelectByStatus: [
        {
          text: this.$t('components.wallet.filters.items_select_by_status.active'),
          value: 'active',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.pending'),
          value: 'pending',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.disapproved'),
          value: 'disapproved',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.able_to_offer'),
          value: 'able_to_offer',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.offered'),
          value: 'offered',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.sold'),
          value: 'sold',
        },
        {
          text: this.$t('components.wallet.filters.items_select_by_status.all'),
          value: null,
        },
      ],
      period: null,
      status: null,
      dateInit: null,
      dateEnd: null,
      showDateRange: false,
      toggleExpandedSelectPeriod: false,
    };
  },

  computed: {
    ...mapGetters([
      'getMyNfesFiles',
    ]),

    hasNfes() {
      return this.getMyNfesFiles.data?.length > 0;
    },
  },

  mounted() {
    this.getMyNfes({ page: 1 });
  },

  methods: {
    ...mapActions([
      'getMyNfes',
    ]),

    changePage(page) {
      this.getMyNfes({
        page,
        by_period: this.period,
        by_status: this.status,
        start_period: this.dateInit,
        end_period: this.dateEnd,
      });

      this.pageStatus = page;
    },

    fetchByFilters(shouldResetDate) {
      if (shouldResetDate) {
        this.dateInit = null;
        this.dateEnd = null;
      }

      this.getMyNfes({
        page: 1,
        by_period: this.period,
        by_status: this.status,
        start_period: this.dateInit,
        end_period: this.dateEnd,
      });
    },

    fetchByDateRange() {
      this.period = null;

      if (this.dateInit?.length === 10 && this.dateEnd?.length === 10) {
        this.getMyNfes({
          start_period: this.dateInit,
          end_period: this.dateEnd,
          by_status: this.status,
        });

        this.toggleExpandedSelectPeriod = !this.toggleExpandedSelectPeriod;
      }
    },

    showInputs() {
      this.showDateRange = !this.showDateRange;
    },

    translateCustomPeriodInputs(value) {
      return this.$t(`components.wallet.filters.items_select_by_period.custom_period.inputs.${value}`);
    },
  },
};
</script>
<style lang="scss" scoped>
  .wallet {
    background: $dashboard_background;
    min-height: 100vh;
    padding: 2em 2em 2em 16em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container-wallet {
      width: 100%;

      .title-wallet {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 40px;
        text-align: center;
      }

      .filters {
        display: flex;
        justify-content: space-between;

        .button-my-offereds {
          width: 100%;
          max-width: 250px;
          height: 46px;
        }

        .content-filters {
          display: flex;

          .select-by-period {
            width: 300px;

            .custom-inputs {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 10px 10px 10px;

              .input-period {
                width: 100px;
                height: 40px;
                border-radius: 10px;
                margin: 8px;
                font-size: 1em;
              }
            }

            .item {
              display: flex;
              margin: 10px 10px 10px 40px;
              font-size: 0.875em;
              cursor: pointer;
            }
          }
        }
      }

      .placeholder-wallet {
        color: $color_secondary;
        font-size: 1em;
        text-align: center;
        display: flex;
      }

      .content-wallet {
        width: 100%;
        height: 100%;
        background: $color_white;
        border-radius: 10px;
        padding: 44px 32px;
        margin-bottom: 20px;

      .content-nfes {
        max-width: 100%;

        .item {
          overflow: auto;
        }
      }
    }
  }
}

  @media (max-width: 960px) {
    .wallet {
      margin-left: 0px;
    }
  }
</style>
